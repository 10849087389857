import React from 'react';
import { LoggerProvider } from './contexts';
import { ToastUI } from './ToastUI';
import { useToast } from './useToast';
const ToastRoot = React.forwardRef((props, ref) => {
    const { config, ...defaultOptions } = props;
    const { show, hide, isVisible, options, data } = useToast({
        defaultOptions
    });
    React.useImperativeHandle(ref, () => ({
        show,
        hide
    }));
    return (<ToastUI isVisible={isVisible} options={options} data={data} hide={hide} show={show} config={config}/>);
});
const toastRef = React.createRef();
export function Toast(props) {
    return (<LoggerProvider enableLogs={false}>
      <ToastRoot ref={toastRef} {...props}/>
    </LoggerProvider>);
}
Toast.show = (params) => {
    toastRef.current?.show(params);
};
Toast.hide = (params) => {
    toastRef.current?.hide(params);
};
